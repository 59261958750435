<template>
  <div>
    <v-toolbar class="no-print" style="margin-bottom:1em">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" style="margin-right:1em" icon @click="gotoSubmissionList()">
            <v-icon left>fal fa-list</v-icon>
          </v-btn>
        </template>
        <span>Back to submission list</span>
      </v-tooltip>
      <v-btn :outlined="!editMode" :color="editMode ? 'green' : ''" @click="editMode = !editMode">
        <v-icon left>{{ editMode ? 'fas' : 'fal' }} fa-pencil</v-icon>
        {{ editMode ? 'Exit Edit Mode' : 'Edit Submission' }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" :disabled="submissionIndex === 0" icon @click="viewSubmission(-1)">
            <v-icon>fal fa-chevron-left</v-icon>
          </v-btn>
        </template>
        <span>View previous submission</span>
      </v-tooltip>
      <v-combobox v-model="submissionStatus" :items="statusOptions" label="Status" style="margin:0 .2em;max-width:180px" outlined dense hide-details @change="updateStatus">
        <template v-slot:prepend-inner>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon small color="primary">fas fa-question-circle</v-icon>
            </template>
            <span>Change the status of this submission. You can select from the list or type in a new status.</span>
          </v-tooltip>
        </template>
      </v-combobox>
      <submission-comments :submission-id="submissionId" :access-role="accessRole"></submission-comments>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" :disabled="submissionIndex === submissionCount - 1" icon @click="viewSubmission(1)">
            <v-icon>fal fa-chevron-right</v-icon>
          </v-btn>
        </template>
        <span>View next submission</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-menu v-if="submissionComments.length > 0">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon>
            <v-icon>fal fa-print</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item @click="printWithComments()">
            <v-list-item-title>Print With Comments</v-list-item-title>
          </v-list-item>
          <v-list-item @click="printWithoutComments()">
            <v-list-item-title>Print Without Comments</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn v-else icon @click="printWithoutComments()">
        <v-icon>fal fa-print</v-icon>
      </v-btn>
      <span style="margin-right:.5em">Layout: </span>
      <v-btn-toggle v-model="viewMode">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on">
              <v-icon>fal fa-stream</v-icon>
            </v-btn>
          </template>
          <span>Form Layout (editable layout using the form layout)</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on">
              <v-icon>fal fa-list-alt</v-icon>
            </v-btn>
          </template>
          <span>List Layout (not editable, printable)</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on">
              <v-icon>fal fa-th-list</v-icon>
            </v-btn>
          </template>
          <span>Table Layout (not editable, printable)</span>
        </v-tooltip>
      </v-btn-toggle>
    </v-toolbar>
    <v-card elevation="0">
      <v-card-title style="text-align:center;display:block">Submitted <span v-if="submissionUser">by: {{ submissionUser.name }}</span> on {{ stringFormatDate(submittedDate) }}</v-card-title>
    </v-card>
    <template v-if="viewMode === 0">
      <v-card v-for="({ title, showTitle, inputs }, index) in sections" :key="'section-' + index">
        <v-card-title v-if="showTitle">{{ title }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col v-for="({ label, info, input, md, lg, inputs:rbInputs }, inputIndex) in inputs" :key="'input-' + inputIndex" :md="md" :lg="lg" :cols="12">
              <div v-if="input === 'html-block'" v-html="info"></div>
              <template v-else-if="input === 'repeatable-block'">
                <v-card v-for="(rbData, rbDataIndex) in submissionData[label]" :key="'rb-block-' + inputIndex + '-' + rbDataIndex">
                  <v-subheader>{{ label }}: {{ rbDataIndex + 1 }}</v-subheader>
                  <v-card-text style="padding-top:0">
                    <v-row>
                      <v-col v-for="({ label:rbLabel, input:rbInput, info:rbInfo, md:rbMd, lg:rbLg }, rbIndex) in rbInputs" :key="'rb-input-' + inputIndex + '-' + rbDataIndex + '-' + rbIndex" :md="rbMd" :lg="rbLg" :cols="12">
                        <div v-if="input === 'html-block'" v-html="rbInfo"></div>
                        <v-textarea v-else-if="rbInput === 'textarea'"
                          :value="typeof (submissionData[label][rbDataIndex][rbLabel]) === 'object' && 'value' in submissionData[label][rbDataIndex][rbLabel] ? submissionData[label][rbDataIndex][rbLabel].value : submissionData[label][rbDataIndex][rbLabel]"
                          :label="rbLabel"
                          :readonly="!editMode"
                          :outlined="editMode"
                          :filled="!editMode"
                          :auto-grow="true"
                          :rows="1"
                          :hint="typeof (submissionData[label][rbDataIndex][rbLabel]) === 'object' && 'original' in submissionData[label][rbDataIndex][rbLabel] ? 'Updated. Original value: ' + submissionData[label][rbDataIndex][rbLabel].original : ''"
                          :hide-details="!(typeof (submissionData[label][rbDataIndex][rbLabel]) === 'object' && 'original' in submissionData[label][rbDataIndex][rbLabel])"
                          persistent-hint
                          @change="(value) => updateData({ label, value, subIndex: rbDataIndex, subLabel: rbLabel, original: submissionData[label] })"
                        ></v-textarea>
                        <v-select v-else-if="rbInput === 'select' || input === 'autocomplete' || input === 'combobox'"
                          :value="typeof (submissionData[label][rbDataIndex][rbLabel]) === 'object' && 'value' in submissionData[label][rbDataIndex][rbLabel] ? submissionData[label][rbDataIndex][rbLabel].value : submissionData[label][rbDataIndex][rbLabel]"
                          :label="rbLabel"
                          :items="Array.isArray(submissionData[label][rbDataIndex][rbLabel]) ? submissionData[label][rbDataIndex][rbLabel] : [submissionData[label][rbDataIndex][rbLabel]]"
                          :multiple="Array.isArray(submissionData[label][rbDataIndex][rbLabel])"
                          :chips="Array.isArray(submissionData[label][rbDataIndex][rbLabel])"
                          :readonly="!editMode"
                          :outlined="editMode"
                          :filled="!editMode"
                          :hint="typeof (submissionData[label][rbDataIndex][rbLabel]) === 'object' && 'original' in submissionData[label][rbDataIndex][rbLabel] ? 'Updated. Original value: ' + (Array.isArray(submissionData[label][rbDataIndex][rbLabel].original) ? submissionData[label][rbDataIndex][rbLabel].original.join('; ') : submissionData[label][rbDataIndex][rbLabel].original) : ''"
                          :hide-details="!(typeof (submissionData[label][rbDataIndex][rbLabel]) === 'object' && 'original' in submissionData[label][rbDataIndex][rbLabel])"
                          persistent-hint
                          @change="(value) => updateData({ label, value, subIndex: rbDataIndex, subLabel: rbLabel, original: submissionData[label] })"
                        ></v-select>
                        <v-checkbox v-else-if="rbInput === 'checkbox'"
                          :value="typeof (submissionData[label][rbDataIndex][rbLabel]) === 'object' && 'value' in submissionData[label][rbDataIndex][rbLabel] ? submissionData[label][rbDataIndex][rbLabel].value : submissionData[label][rbDataIndex][rbLabel]"
                          :label="rbLabel"
                          :readonly="!editMode"
                          :outlined="editMode"
                          :filled="!editMode"
                          :hint="typeof (submissionData[label][rbDataIndex][rbLabel]) === 'object' && 'original' in submissionData[label][rbDataIndex][rbLabel] ? 'Updated. Original value: ' + submissionData[label][rbDataIndex][rbLabel].original : ''"
                          :hide-details="!(typeof (submissionData[label][rbDataIndex][rbLabel]) === 'object' && 'original' in submissionData[label][rbDataIndex][rbLabel])"
                          persistent-hint
                          @change="(value) => updateData({ label, value, subIndex: rbDataIndex, subLabel: rbLabel, original: submissionData[label] })"
                        ></v-checkbox>
                        <v-switch v-else-if="rbInput === 'switch'"
                          :value="typeof (submissionData[label][rbDataIndex][rbLabel]) === 'object' && 'value' in submissionData[label][rbDataIndex][rbLabel] ? submissionData[label][rbDataIndex][rbLabel].value : submissionData[label][rbDataIndex][rbLabel]"
                          :label="rbLabel"
                          :readonly="!editMode"
                          :outlined="editMode"
                          :filled="!editMode"
                          :hint="typeof (submissionData[label][rbDataIndex][rbLabel]) === 'object' && 'original' in submissionData[label][rbDataIndex][rbLabel] ? 'Updated. Original value: ' + submissionData[label][rbDataIndex][rbLabel].original : ''"
                          :hide-details="!(typeof (submissionData[label][rbDataIndex][rbLabel]) === 'object' && 'original' in submissionData[label][rbDataIndex][rbLabel])"
                          persistent-hint
                          @change="(value) => updateData({ label, value, subIndex: rbDataIndex, subLabel: rbLabel, original: submissionData[label] })"
                        ></v-switch>
                        <div v-else-if="rbInput === 'signature'">
                          <img v-if="submissionData[label][rbDataIndex][rbLabel].signature" :src="submissionData[label][rbDataIndex][rbLabel].signature" style="width:300px;height:150px;display:block;background-color:white" />
                          <div v-if="submissionData[label][rbDataIndex][rbLabel].name" style="font-size:1.5em;font-weight:bold;margin:0 1em 0 1em;">{{ submissionData[label][rbDataIndex][rbLabel].name }}</div>
                          <div v-if="submissionData[label][rbDataIndex][rbLabel].date" style="font-size:1em;font-weight:bold;margin:0 1em 1em 1.5em;">Signed: {{ stringFormatDate(submissionData[label][rbDataIndex][rbLabel].date) }}</div>
                        </div>
                        <submission-file-upload v-else-if="rbInput === 'file-upload'" :submission-data="submissionData[label][rbDataIndex][rbLabel]"></submission-file-upload>
                        <v-text-field v-else
                          :value="typeof (submissionData[label][rbDataIndex][rbLabel]) === 'object' && 'value' in submissionData[label][rbDataIndex][rbLabel] ? submissionData[label][rbDataIndex][rbLabel].value : submissionData[label][rbDataIndex][rbLabel]"
                          :label="rbLabel"
                          :readonly="!editMode"
                          :outlined="editMode"
                          :filled="!editMode"
                          :hint="typeof (submissionData[label][rbDataIndex][rbLabel]) === 'object' && 'original' in submissionData[label][rbDataIndex][rbLabel] ? 'Updated. Original value: ' + submissionData[label][rbDataIndex][rbLabel].original : ''"
                          :hide-details="!(typeof (submissionData[label][rbDataIndex][rbLabel]) === 'object' && 'original' in submissionData[label][rbDataIndex][rbLabel])"
                          persistent-hint
                          @change="(value) => updateData({ label, value, subIndex: rbDataIndex, subLabel: rbLabel, original: submissionData[label] })"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </template>
              <v-textarea v-else-if="input === 'textarea'"
                :value="typeof (submissionData[label]) === 'object' && 'value' in submissionData[label] ? submissionData[label].value : submissionData[label]"
                :label="label"
                :auto-grow="true"
                :rows="1"
                :readonly="!editMode"
                :outlined="editMode"
                :filled="!editMode"
                :hint="typeof (submissionData[label]) === 'object' && 'original' in submissiionData[label] ? 'Update. Original value: ' + submissionData[label] : ''"
                :hide-details="!(typeof (submissionData[label]) === 'object' && 'original' in submissionData[label])"
                @change="(value) => updateData({ label, value, original: submissionData[label] })"
              ></v-textarea>
              <v-select v-else-if="input === 'select' || input === 'autocomplete' || input === 'combobox'"
                :value="typeof (submissionData[label]) === 'object' && 'value' in submissionData[label] ? submissionData[label].value : submissionData[label]"
                :label="label"
                :items="Array.isArray(submissionData[label]) ? submissionData[label] : [submissionData[label]]"
                :multiple="Array.isArray(submissionData[label])"
                :chips="Array.isArray(submissionData[label])"
                :readonly="!editMode"
                :outlined="editMode"
                :filled="!editMode"
                :hint="typeof (submissionData[label]) === 'object' && 'original' in submissionData[label] ? 'Updated. Original value: ' + (Array.isArray(submissionData[label].original) ? submissionData[label].original.join(';') : submissionData[label].original) : ''"
                :hide-details="!(typeof (submissionData[label]) === 'object' && 'original' in submissionData[label])"
                persistent-hint
                @change="(value) => updateData({ label, value, original: submissionData[label] })"
              ></v-select>
              <submission-file-upload v-else-if="input === 'file-upload'" :submission-data="submissionData[label]"></submission-file-upload>
              <v-text-field v-else-if="input === 'file'" :value="submissionData[label]" :label="label" outlined hide-details>
                <template v-slot:append>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" :href="submissionData[label]" icon target="_blank" style="margin-top:-5px">
                        <v-icon>fal fa-sign-in-alt</v-icon>
                      </v-btn>
                    </template>
                    <span>View file in Google Drive (opens in new tab)</span>
                  </v-tooltip>
                </template>
                </v-text-field>
              <v-checkbox v-else-if="input === 'checkbox'"
                :value="typeof (submissionData[label]) === 'object' && 'value' in submissionData[label] ? submissionData[label].value : submissionData[label]"
                :label="label"
                :readonly="!editMode"
                :outlined="editMode"
                :filled="!editMode"
                :hint="typeof (submissionData[label]) === 'object' && 'original' in submissionData[label] ? 'Updated. Original value: ' + submissionData[label].original : ''"
                :hide-details="!(typeof (submissionData[label]) === 'object' && 'original' in submissionData[label])"
                persistent-hint
                @change="(value) => updateData({ label, value, original: submissionData[label] })"
              ></v-checkbox>
              <v-switch v-else-if="input === 'switch'"
                :value="typeof (submissionData[label]) === 'object' && 'value' in submissionData[label] ? submissionData[label].value : submissionData[label]"
                :label="label"
                :readonly="!editMode"
                :outlined="editMode"
                :filled="!editMode"
                :hint="typeof (submissionData[label]) === 'object' && 'original' in submissionData[label] ? 'Updated. Original value: ' + submissionData[label].original : ''"
                :hide-details="!(typeof (submissionData[label]) === 'object' && 'original' in submissionData[label])"
                persistent-hint
                @change="(value) => updateData({ label, value, original: submissionData[label] })"
              ></v-switch>
              <div v-else-if="input === 'signature'">
                <img v-if="submissionData[label].signature" :src="submissionData[label].signature" style="width:300px;height:150px;display:block;background-color:white" />
                <div v-if="submissionData[label].name" style="font-size:1.5em;font-weight:bold;margin:0 1em 0 1em;">{{ submissionData[label].name }}</div>
                <div v-if="submissionData[label].date" style="font-size:1em;font-weight:bold;margin:0 1em 1em 1.5em;">Signed: {{ stringFormatDate(submissionData[label].date) }}</div>
              </div>
              <!-- <v-list v-else-if="input === 'file-upload'">
                <v-list-item v-for="{ id, name, filetype } of submissionData[label]" :key="id" target="_blank" :href="'https://drive.google.com/file/d/' + id + '/view'">
                  <v-list-item-avatar>
                    <v-icon v-if="['image', 'audio', 'video'].includes(filetype.substring(0, 5))">fal fa-file-{{ filetype.substring(0, 5) }}</v-icon>
                    <v-icon v-else-if="filetype === 'application/pdf'">fal fa-file-pdf</v-icon>
                    <v-icon v-else-if="filetype === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'">fal fa-file-excel</v-icon>
                    <v-icon v-else-if="filetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'">fal fa-file-word</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title>{{ name }}</v-list-item-title>
                </v-list-item>
              </v-list> -->
              <v-text-field v-else
                :value="typeof (submissionData[label]) === 'object' && 'value' in submissionData[label] ? submissionData[label].value : submissionData[label]"
                :label="label"
                :readonly="!editMode"
                :outlined="editMode"
                :filled="!editMode"
                :hint="typeof (submissionData[label]) === 'object' && 'original' in submissionData[label] ? 'Updated. Original value: ' + submissionData[label].original : ''"
                :hide-details="!(typeof (submissionData[label]) === 'object' && 'original' in submissionData[label])"
                persistent-hint
                @change="(value) => updateData({ label, value, original: submissionData[label] })"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
    <template v-else-if="viewMode === 1">
      <v-card v-for="({ title, showTitle, inputs }, index) in sections" :key="'section-' + index">
        <v-card-title v-if="showTitle">{{ title }}</v-card-title>
        <v-list dense>
          <template v-for="({ label, input, inputs:rbInputs }, inputIndex) in inputs">
            <template v-if="input === 'html-block'"></template>
            <template v-else-if="input === 'repeatable-block'">
              <v-list-item v-for="(rbData, rbDataIndex) in submissionData[label]" :key="'rb-block-' + inputIndex + '-' + rbDataIndex">
                <v-list-group :value="true" append-icon="" dense no-action disabled>
                  <template v-slot:activator>
                    <v-subheader>{{ label }}: {{ rbDataIndex + 1 }}</v-subheader>
                  </template>
                  <v-list-item v-for="({ label:rbLabel }, rbIndex) in rbInputs" :key="'rb-input-' + rbIndex">
                    <v-list-item-content>
                      <v-list-item-title>{{ rbLabel }}</v-list-item-title>
                      <v-list-item-subtitle style="white-space:normal">{{ Array.isArray(submissionData[label][rbDataIndex][rbLabel]) ? submissionData[label][rbDataIndex][rbLabel].reduce((acc, val) => { if (acc !== '') acc += ', '; if (typeof (val) === 'string') { acc += val } else { acc += val.value } return acc }, '') : submissionData[label][rbDataIndex][rbLabel] }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list-item>
            </template>
            <v-list-item v-else-if="input === 'file-upload'" :key="'file-input-' + inputIndex">
              <v-list-item-content>
                <v-list-item-title>{{ label }}</v-list-item-title>
                <submission-file-upload :submission-data="submissionData[label]"></submission-file-upload>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else :key="'input-' + inputIndex">
              <v-list-item-content>
                <v-list-item-title>{{ label }}</v-list-item-title>
                <v-list-item-subtitle style="white-space:normal">{{ Array.isArray(submissionData[label]) ? submissionData[label].reduce((acc, val) => { if (acc !== '') acc += ', '; if (typeof (val) === 'string') { acc += val } else { acc += val.value } return acc }, '') : submissionData[label] }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action v-if="input === 'file'">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" :href="submissionData[label]" icon target="_blank" style="margin-top:-5px">
                      <v-icon>fal fa-sign-in-alt</v-icon>
                    </v-btn>
                  </template>
                  <span>View file in Google Drive (opens in new tab)</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </v-card>
    </template>
    <template v-else-if="viewMode === 2">
      <v-card v-for="({ title, inputs }, index) in sections" :key="'section-' + index">
        <v-card-title>{{ title }}</v-card-title>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Field</th>
                <th class="text-left">Value</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="({ label, input, inputs:rbInputs }, inputIndex) in inputs">
                <template v-if="input === 'html-block'"></template>
                <template v-else-if="input === 'repeatable-block'">
                  <tr v-for="(rbData, rbDataIndex) in submissionData[label]" :key="'rb-block-' + inputIndex + '-' + rbDataIndex + '-label'">
                    <td colspan="2">
                      <div>{{ label }}: {{ rbDataIndex + 1 }}</div>
                      <table style="margin-left:32px">
                        <tr v-for="({ label:rbLabel }, rbIndex) in rbInputs" :key="'rb-input-' + inputIndex + '-' + rbDataIndex + '-' + rbIndex">
                          <td>{{ rbLabel }}</td>
                          <td>{{ rbData[rbLabel] }}</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </template>
                <tr v-else-if="input === 'file-upload'" :key="'file-upload-' + inputIndex" :submission-data="submissionData[label]">
                  <td style="min-width:150px">{{ label }}</td>
                  <td>
                    <submission-file-upload :submission-data="submissionData[label]"></submission-file-upload>
                  </td>
                </tr>
                <tr v-else-if="input === 'file'" :key="'file-' + inputIndex">
                  <td style="min-width:150px">{{ label }}</td>
                  <td>
                    <a :href="submissionData[label]" target="_blank">{{ submissionData[label] }}</a>
                  </td>
                </tr>
                <tr v-else-if="input === 'signature'" :key="'signature-' + inputIndex">
                  <td style="min-width:150px">{{ label }}</td>
                  <td>
                    <img v-if="submissionData[label].signature" :src="submissionData[label].signature" style="width:300px;height:150px;display:block;background-color:white" />
                    <div v-if="submissionData[label].name" style="font-size:1.5em;font-weight:bold;margin:0 1em 0 1em;">{{ submissionData[label].name }}</div>
                    <div v-if="submissionData[label].date" style="font-size:1em;font-weight:bold;margin:0 1em 1em 1.5em;">Signed: {{ stringFormatDate(submissionData[label].date) }}</div>
                  </td>
                </tr>
                <tr v-else :key="'input-' + inputIndex">
                  <td style="min-width:150px">{{ label }}</td>
                  <td>{{ Array.isArray(submissionData[label]) ? submissionData[label].reduce((acc, val) => { if (acc !== '') acc += ', '; if (typeof (val) === 'string') { acc += val } else { acc += val.value } return acc }, '') : submissionData[label] }}</td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </template>
    <v-card v-if="paymentDetail.length > 0">
      <v-card-title>Payment Details</v-card-title>
      <v-card-text>
        <v-row>
          <v-col v-for="{ label, value } of paymentDetail" :key="label">
            <v-text-field :label="label" :value="value" readonly outlined></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div v-if="submissionComments.length > 0 && printComments" class="show-on-print">
      <v-card>
        <v-card-title>Comments on the Submission</v-card-title>
        <v-card-text>
          <submission-comment-list :submission-id="submissionId" :access-role="accessRole"></submission-comment-list>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<style>
@media screen {
  .show-on-print {
    display: none;
  }
}
@media print {
  .show-on-print {
    display: block;
  }
}
</style>
<script>
import { ref, computed, watch } from '@vue/composition-api'
import { stringFormatDate } from '../../../helpers/formatters'

export default {
  components: {
    SubmissionComments: () => import('@/components/forms/admin/submission/CommentDialog'),
    SubmissionCommentList: () => import('@/components/forms/admin/submission/CommentList'),
    SubmissionFileUpload: () => import('@/components/forms/admin/submission/FileUpload')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user)
    const statusOptions = ref([ 'Pending', 'Submitted', 'Processed' ])
    const formFields = ref([])
    const submissionId = computed(() => root.$route.params['id'])
    const submissionCount = computed(() => root.$store.state.forms.admin.submission.count)

    const submissionIndex = computed(() => root.$store.state.forms.admin.submission.index)
    const submissionRevisionId = ref(null)
    const submissionData = ref({})
    const submissionComments = ref([])
    const submissionUser = ref(null)
    const submittedDate = ref(null)
    const paymentDetail = ref([])
    const viewMode = ref(0)
    const printComments = ref(false)

    function printWithComments () {
      printComments.value = true
      setTimeout(() => window.print(), 500)
    }
    function printWithoutComments () {
      printComments.value = false
      if (submissionComments.value.length > 0) {
        setTimeout(() => window.print(), 500)
      } else {
        window.print()
      }
    }

    watch(submissionId, () => {
      loadSubmission()
    })

    const formId = ref(null)
    const accessRole = ref('')

    const paymentMap = {
      paypalId: 'PayPal ID',
      logId: 'Portal Payment Log ID',
      dep: 'Department',
      orgn: 'ORG Code'
    }

    function loadSubmission () {
      root.$feathers.service('forms/submission').get(submissionId.value).then((item) => {
        if ('payment' in item) {
          paymentDetail.value = []
          for (const field in item.payment) {
            const value = item.payment[field]
            if (value != null && value !== '') {
              let label = (field in paymentMap ? paymentMap[field] : field)
              paymentDetail.value.push({ label, value })
            }
          }
        }
        formId.value = item.form.base
        // Load the form and make sure this user has permission to view the submission
        root.$feathers.service('forms/base').get(item.form.base).then((baseData) => {
          let hasAccess = false
          if ('access' in baseData && 'users' in baseData.access) {
            for (let i = 0; i < baseData.access.users.length; i++) {
              if (baseData.access.users[i].pidm === user.value.pidm) {
                if (baseData.access.users[i].role === 'Admin' || baseData.access.users[i].role === 'Reviewer') {
                  hasAccess = true
                  accessRole.value = baseData.access.users[i].role
                  break
                }
              }
            }
          }
          if (!hasAccess) {
            root.$store.dispatch('main/snackbar', { active: true, color: 'error', timeout: 8000, text: 'You do not have access permission to the submission that you tried to view.' })
            root.$router.push('/forms/admin')
            return
          }
          submissionData.value = {}
          submissionComments.value = item.comments
          submittedDate.value = item.submittedDate
          submissionStatus.value = item.status
          if ('statusOptions' in baseData && Array.isArray(baseData.statusOptions) && baseData.statusOptions.length > 0) {
            statusOptions.value = baseData.statusOptions
          }
          if ('dataArr' in item) {
            for (let { field, value, original } of item.dataArr) {
              if (Array.isArray(value) && Array.isArray(value[0])) {
                const arr = []
                for (let i = 0; i < value.length; i++) {
                  const temp = {}
                  for (const { field: subField, value: subValue, original: subOriginal } of value[i]) {
                    if (subOriginal) {
                      temp[subField] = { value: subValue, original: subOriginal }
                    } else {
                      temp[subField] = subValue
                    }
                  }
                  arr.push(temp)
                }
                submissionData.value[field] = arr
              } else if (original) {
                submissionData.value[field] = { value, original }
              } else {
                submissionData.value[field] = value
              }
            }
          } else {
            for (let field in item.data) {
              submissionData.value[field] = item.data[field]
            }
          }
          if ('user' in item && item.user !== '') {
            root.$feathers.service('users').find({ query: { _id: item.user } }).then(({ data }) => {
              submissionUser.value = data[0]
            })
          }
          sections.value = []
          root.$feathers.service('forms/revision').get(item.form.revision).then((data) => { sections.value = data.sections })
        })
      })
    }

    const sections = ref([])

    const submissionStatus = ref('')
    function updateStatus (val) {
      root.$feathers.service('forms/submission').patch(submissionId.value, { status: val }).then((data) => {
        root.$store.dispatch('main/snackbar', { active: true, color: 'success', timeout: 6000, text: 'Status updated successfully' })
      }).catch((e) => {
        root.$store.dispatch('main/snackbar', { active: true, color: 'error', timeout: 10000, text: 'Error updating submission: ' + e })
      })
      let included = false
      for (let i = 0; i < statusOptions.value.length; i++) {
        if (statusOptions.value[i] === val) included = true
      }
      if (!included) {
        root.$feathers.service('forms/base').patch(formId.value, { $push: { statusOptions: val } }).then((data) => {
          statusOptions.value.push(val)
        })
      }
    }

    function gotoSubmissionList () {
      if (root.$store.state.forms.admin.activeTab === 1) {
        root.$store.commit('forms/setAdminTab', accessRole.value === 'Admin' ? 3 : 0)
      }
      root.$router.push('/forms/admin/' + formId.value)
    }

    function viewSubmission (increment) {
      root.$store.dispatch('forms/adminLoadIndexedSubmissionId', { formId: formId.value, increment }).then((id) => {
        root.$store.commit('forms/setAdminSubmissionIndex', root.$store.state.forms.admin.submission.index + increment)
        root.$router.push('/forms/admin/submission/' + id)
      })
    }

    const editMode = ref(false)
    async function updateData ({ label, value, subIndex, subLabel, original }) {
      if (!editMode.value) return
      const aggregate = [
        { $match: { _id: submissionId.value } },
        { $unwind: '$dataArr' },
        { $project: {
          field: '$dataArr.field',
          value: '$dataArr.value',
          original: '$dataArr.original'
        } },
        { $match: { field: label } }
      ]
      if (subLabel) {
        aggregate.push({ $unwind: { path: '$value', includeArrayIndex: 'index', preserveNullAndEmptyArrays: true } })
        aggregate.push({ $match: { index: subIndex } })
        aggregate.push({ $unwind: '$value' })
        aggregate.push({ $project: { field: '$value.field', value: '$value.value', original: '$value.original' } })
        aggregate.push({ $match: { field: subLabel } })
      }
      let patch = {}
      const arrayFilters = [{ 'elem.field': label }]
      const { data } = await root.$feathers.service('forms/submission').find({ query: { aggregate } })
      if (data.length === 0) {
        patch.$push = { dataArr: { field: label, value, original: '' } }
      } else {
        if (subIndex != null) {
          const field = 'dataArr.$[elem].value.' + subIndex + '.$[subEl].value'
          const origField = 'dataArr.$[elem].value.' + subIndex + '.$[subEl].original'
          arrayFilters.push({ 'subEl.field': subLabel })
          if (!('original' in data[0])) {
            patch.$set = {
              [field]: value,
              [origField]: original[subIndex][subLabel]
            }
          } else {
            patch.$set = { [field]: value }
          }
        } else {
          const field = 'dataArr.$[elem].value'
          if (!('original' in data[0])) {
            console.log('original not in data')
            patch.$set = {
              [field]: value,
              'dataArr.$[elem].original': original
            }
          }
        }
      }
      await root.$feathers.service('forms/submission').patch(submissionId.value, patch, { query: { arrayFilters } })
    }

    if (submissionId.value !== '') {
      loadSubmission()
    }

    return {
      statusOptions,
      formFields,
      submissionCount,
      stringFormatDate,
      submissionId,
      submissionIndex,
      submissionRevisionId,
      submissionData,
      submissionComments,
      submissionUser,
      submittedDate,
      paymentDetail,
      viewMode,
      printComments,
      printWithComments,
      printWithoutComments,
      formId,
      accessRole,
      loadSubmission,
      sections,
      submissionStatus,
      updateStatus,
      gotoSubmissionList,
      viewSubmission,
      editMode,
      updateData
    }
  }
}
</script>
